import "./charts.css";

import {
  Line,
  ComposedChart,
  Legend,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

const HourlyChart = ({
  hourlyChartSeries,
  displayType,
  h2DailyStorage,
  h2SeasonalStorage,
}) => {
  return (
    <div className="outerChartDiv">
      <div className="innerChartDiv">
        <ResponsiveContainer>
          <ComposedChart data={hourlyChartSeries}>
            <XAxis height={40}>
              <Label value="Hour of day" offset={-10} position="bottom" />
            </XAxis>

            <YAxis width={80}>
              <Label value="Power (MW)" angle={-90} position="insideLeft" />
            </YAxis>
            <Legend verticalAlign="top" iconType="plainline" />
            <Tooltip formatter={(val) => Math.floor(val)} />

            {displayType === "summary" && (
              <Line
                name={
                  h2DailyStorage
                    ? "Total electricity demand (without H2 storage)"
                    : "Total electricity demand"
                }
                type="monotone"
                dataKey="elec_for_all"
                stroke={h2DailyStorage ? "#f7dfe4" : "#ef476f"}
                dot={false}
                strokeWidth={3}
              />
            )}
            {displayType === "summary" && h2DailyStorage && (
              <Line
                name="Total electricity demand (with daily H2 storage)"
                type="monotone"
                dataKey="elec_for_all_wDailyS"
                stroke={h2SeasonalStorage ? "#f2b3c1" : "#ef476f"}
                dot={false}
                strokeWidth={3}
              />
            )}

            {displayType === "summary" && (
              <Line
                name="Total electricity generation"
                type="monotone"
                dataKey="tot_generation"
                stroke="#00afb9"
                dot={false}
                strokeWidth={3}
              />
            )}
            {displayType === "summary" &&
              h2DailyStorage &&
              h2SeasonalStorage && (
                <Line
                  name="Total electricity demand (with seasonal H2 storage)"
                  type="monotone"
                  dataKey="elec_for_all_wDailyS_wSeasonalS"
                  stroke="#ef476f"
                  strokeDasharray="7 7"
                  dot={false}
                  strokeWidth={3}
                />
              )}

            {displayType === "demand" && (
              <Area
                name="Non-heat electricity demand"
                type="monotone"
                stackId="2"
                dataKey="elec_avg_tot"
                stroke="#B3B3B3"
                fill="grey"
              />
            )}
            {displayType === "demand" && (
              <Area
                name="electricity for heat pumps"
                type="monotone"
                stackId="2"
                dataKey="elec_for_hp"
                stroke="#7F84BD"
                fill="#293291"
              />
            )}
            {displayType === "demand" && !h2DailyStorage && (
              <Area
                name="electricity for hydrogen"
                type="monotone"
                stackId="2"
                dataKey="elec_for_h2"
                stroke="#ee6c4d"
                fill="#ee6c4d"
              />
            )}
            {displayType === "demand" &&
              h2DailyStorage &&
              !h2SeasonalStorage && (
                <Area
                  name="electricity for hydrogen (with daily storage)"
                  type="monotone"
                  stackId="2"
                  dataKey="elec_for_h2_wDailyS"
                  stroke="#ee6c4d"
                  fill="#ee6c4d"
                />
              )}
            {displayType === "demand" &&
              h2DailyStorage &&
              h2SeasonalStorage && (
                <Area
                  name="electricity for hydrogen (with storage)"
                  type="monotone"
                  stackId="2"
                  dataKey="elec_for_h2_wDailyS_wSeasonalS"
                  stroke="#ee6c4d"
                  fill="#ee6c4d"
                />
              )}

            {displayType === "supply" && (
              <Area
                name="nuclear"
                type="monotone"
                stackId="1"
                dataKey="nuclear"
                stroke="grey"
                fill="#6d6875"
              />
            )}
            {displayType === "supply" && (
              <Area
                type="monotone"
                stackId="1"
                dataKey="solar"
                stroke="#ffb703"
                fill="#ffb703"
                dot={false}
              />
            )}
            {displayType === "supply" && (
              <Area
                name="offshore"
                type="monotone"
                stackId="1"
                dataKey="offshore"
                stroke="#7AC5D7"
                fill="#219ebc"
              />
            )}
            {displayType === "supply" && (
              <Area
                name="onshore"
                type="monotone"
                stackId="1"
                dataKey="onshore"
                stroke="#BBDFF0"
                fill="#8ecae6"
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default HourlyChart;
