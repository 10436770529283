import * as Action from "../action-types";
import exportJson from "./export.json";

import * as sliderConfig from "../../sliderConfig";
import { recalculate, updateFocusDay } from "./calculations";
import { shortfallDaysInitial } from "./shortfallDaysInitial";
import { runOptimisation } from "./optimisation";

let initialDailyAvgs = new Array();
for (var i = 0; i < 365; i++) initialDailyAvgs.push(new Object());
// for (var i = 0; i < 52; i++) initialDailyAvgs.push(new Object());

let initialhourlyChartSeries = new Array();
for (var i = 0; i < 24; i++) initialhourlyChartSeries.push(new Object());

const initialState = {
  hourlySeries: exportJson,
  dailyAvgs: initialDailyAvgs,
  hourlyChartSeries: initialhourlyChartSeries,
  focusDay: 183,
  focusPercentile: 50,
  // Initialise to all so all are calculated
  changedSliders: ["offshore", "onshore", "solar", "nuclear", "heat"],
  needsRecalculation: false,
  h2StorageSeries: [],
  storage: { 50: {}, 95: {}, 90: {} },
  modelInputs: {
    offshore: sliderConfig.offshoreInitialisedCapacity,
    onshore: sliderConfig.onshoreInitialisedCapacity,
    solar: sliderConfig.solarInitialisedCapacity,
    nuclear: sliderConfig.nuclearInitialisedCapacity,
    heat: sliderConfig.heatInitialisedDemand,
    hpMix: sliderConfig.hpMixInitial,
    hpEff: sliderConfig.hpEffInitial,
  },
  results: {
    shortfallDays: shortfallDaysInitial,
  },
  optimisationResults: [],
};

function rootReducer(state = initialState, action) {
  if (action.type === Action.RECALCULATE) {
    return recalculate(state);
  } else if (action.type === Action.REGISTER_SLIDER_CHANGE) {
    return {
      ...state,
      changedSliders: [
        ...state.changedSliders,
        // Add slider if it doesn't already exist
        ...(state.changedSliders.includes(action.slider)
          ? []
          : [action.slider]),
      ],
      modelInputs: { ...state.modelInputs, [action.slider]: action.value },
      needsRecalculation: true,
    };
  } else if (action.type === Action.UPDATE_FOCUS_DAY) {
    return updateFocusDay(state, action.day, action.percentile);
  } else if (action.type === Action.RUN_OPTIMISATION) {
    return runOptimisation(state);
  }
  return state;
}

export default rootReducer;
