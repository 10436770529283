import { H2_EFFICIENCY_BEFORE_BOILER } from "./modelConstants";

let LPF = require("lpf");

let ELEC_TO_H2_CONVERSION_FACTOR = 24 / (1000 * H2_EFFICIENCY_BEFORE_BOILER);

export const calculateDailyStorage = (state) => {
  console.log("Calculating storage...");
  let newHourlySeries = state.hourlySeries;

  // NOTE: elecSurplusSeries does NOT include H2.
  ["50", "90", "95"].forEach((percentile) => {
    let {
      elecSurplusSeries,
      h2ElecDailyAvgs,
      h2ElecDailyAvgsWithSeasonal,
      elecSurplusDailyAvgs,
      maxDefecits,
    } = getStorageAvgs(state, percentile);

    newHourlySeries = newHourlySeries.map((hourObj, i) => {
      var updatedHour = hourObj;
      updatedHour["elec_surplus_exc_h2_" + percentile] = elecSurplusSeries[i];

      let day = Math.floor(i / 24);
      let surplusScalingFactor = Math.min(
        // Constrains to 0 (so we don't adjust by more than the H2 available)
        Math.abs(
          h2ElecDailyAvgs[day] / (elecSurplusDailyAvgs[day] - maxDefecits[day])
        ),
        // Constrains to how much we need to change it
        1
      );
      let surplusScalingFactorWithSeasonal = Math.min(
        // Constrains to 0 (so we don't adjust by more than the H2 available)
        Math.abs(
          h2ElecDailyAvgsWithSeasonal[day] /
            (elecSurplusDailyAvgs[day] - maxDefecits[day])
        ),
        // Constrains to how much we need to change it
        1
      );
      // console.log(`day: ${day}   sf: ${surplusScalingFactorWithSeasonal}`);

      let offset =
        updatedHour["elec_surplus_exc_h2_" + percentile] -
        elecSurplusDailyAvgs[day];

      updatedHour["elec_for_h2_wDailyS_" + percentile] =
        h2ElecDailyAvgs[day] + surplusScalingFactor * offset;

      updatedHour["elec_for_h2_wDailyS_wSeasonalS_" + percentile] =
        h2ElecDailyAvgsWithSeasonal[day] +
        surplusScalingFactorWithSeasonal * offset;

      let elecExcludingH2 =
        updatedHour["elec_for_all_" + percentile] -
        updatedHour["elec_for_h2_" + percentile];

      updatedHour["elec_for_all_wDailyS_" + percentile] =
        elecExcludingH2 + updatedHour["elec_for_h2_wDailyS_" + percentile];

      updatedHour["elec_for_all_wDailyS_wSeasonalS_" + percentile] =
        elecExcludingH2 +
        updatedHour["elec_for_h2_wDailyS_wSeasonalS_" + percentile];

      return updatedHour;
    });
  });

  return {
    ...state,
    hourlySeries: newHourlySeries,
  };
};

export const getStorageAvgs = (state, percentile) => {
  let elecSurplusSeries = [];

  let elecSurplusDailyAvgs = [];
  let h2ElecDailyAvgs = [];
  let h2ElecDailyAvgsWithSeasonal = [];
  let maxDefecits = []; // == min surplus

  for (let day = 0; day < 365; day++) {
    let elecSurplusSum = 0;
    let h2ElecSum = 0;
    let maxDefecit = Infinity;
    for (let hour = 0; hour < 24; hour++) {
      let index = hour + day * 24;
      let hourObj = state.hourlySeries[index];

      h2ElecSum += hourObj["elec_for_h2_" + percentile];

      // The surplus assuming we're starting again for assigning H2
      let elecSurplus =
        hourObj["tot_generation_" + percentile] -
        (hourObj["elec_for_all_" + percentile] -
          hourObj["elec_for_h2_" + percentile]);
      elecSurplusSum += elecSurplus;
      elecSurplusSeries.push(elecSurplus);
      // Should be negative. Max defecit = min surplus (the hardest moment in the day)
      maxDefecit = elecSurplus < maxDefecit ? elecSurplus : maxDefecit;
    }

    elecSurplusDailyAvgs.push(elecSurplusSum / 24);
    h2ElecDailyAvgs.push(h2ElecSum / 24);
    h2ElecDailyAvgsWithSeasonal.push(
      h2ElecSum / 24 - state.dailyAvgs[day]["seasonal_h2_used_50"]
    );

    maxDefecits.push(maxDefecit);
  }

  return {
    elecSurplusSeries,
    elecSurplusDailyAvgs,
    h2ElecDailyAvgs,
    h2ElecDailyAvgsWithSeasonal,
    maxDefecits,
  };
};

export const calculateSeasonalStorage = (state) => {
  let newStorage = state.storage;
  let newDailyAvgs = state.dailyAvgs;

  newStorage[50]["tot_surplus"] = 0;
  newStorage[50]["tot_pos"] = 0;
  newStorage[50]["tot_neg"] = 0;

  newDailyAvgs.forEach((day) => {
    let finalElecSurplus = day["elec_surplus_final_50"];
    let h2 = day["elec_for_h2_50"];
    // Count up total surplus
    newStorage[50]["tot_surplus"] += finalElecSurplus;

    if (finalElecSurplus > 0) {
      newStorage[50]["tot_pos"] += Math.min(finalElecSurplus, h2);
    } else {
      newStorage[50]["tot_neg"] -= finalElecSurplus;
    }
  });

  newDailyAvgs.map((day, i) => {
    let newDay = day;
    let finalElecSurplus = newDay["elec_surplus_final_50"];
    let finalElecSurplusSmooth = newDay["elec_surplus_final_50_smooth"];

    let h2UsedElec = getH2UsedElec(newStorage, finalElecSurplus);
    let h2UsedElecSmooth = getH2UsedElec(newStorage, finalElecSurplusSmooth);

    newDay["seasonal_h2_used_50"] = h2UsedElec;
    // Convert from MW of electricity to GWh H2
    newDay["seasonal_h2_used_mwh_h2_50"] =
      h2UsedElec * ELEC_TO_H2_CONVERSION_FACTOR;

    newDay["seasonal_h2_used_mwh_h2_50_smooth"] =
      h2UsedElecSmooth * ELEC_TO_H2_CONVERSION_FACTOR;

    newDay["elec_for_all_wSeasonalS_50"] =
      newDay["elec_for_all_50"] - h2UsedElec;

    newDay["elec_for_all_wSeasonalS_50_smooth"] =
      newDay["elec_for_all_50"] - h2UsedElecSmooth;

    newDay["elec_for_h2_wDailyS_wSeasonalS_50"] =
      newDay["elec_for_h2_50"] - h2UsedElec;

    newDay["elec_for_h2_wDailyS_wSeasonalS_50_smooth"] =
      newDay["elec_for_h2_50"] - h2UsedElecSmooth;

    return newDay;
  });

  let newState = {
    ...state,
    dailyAvgs: newDailyAvgs,
    storage: newStorage,
  };

  return newState;
};

const getH2UsedElec = (newStorage, finalElecSurplus) => {
  let h2UsedElec;
  // If we can succesfully re-allocate all
  if (newStorage[50]["tot_pos"] >= newStorage[50]["tot_neg"]) {
    if (finalElecSurplus > 0) {
      h2UsedElec =
        -(finalElecSurplus / newStorage[50]["tot_pos"]) *
        newStorage[50]["tot_neg"];
    } else {
      // Wipe out all defecit
      h2UsedElec = -finalElecSurplus;
    }
  } else {
    if (finalElecSurplus > 0) {
      // Use all available surplus
      h2UsedElec = -finalElecSurplus;
    } else {
      h2UsedElec =
        -(finalElecSurplus / newStorage[50]["tot_neg"]) *
        newStorage[50]["tot_pos"];
    }
  }
  return h2UsedElec;
};

export const smoothStorageSeriesForDisplay = (state) => {
  // Does not replace original series, adds a new smooth one instead
  let newDailyAvgs = state.dailyAvgs;
  let smoothedNewSeries = {};

  LPF.smoothing = 0.05;

  let seriesToSmooth = ["elec_surplus_final_50"];
  seriesToSmooth.forEach((seriesName) => {
    smoothedNewSeries[seriesName] = [];
  });

  newDailyAvgs.forEach((day) => {
    seriesToSmooth.forEach((seriesName) => {
      smoothedNewSeries[seriesName].push(day[seriesName]);
    });
  });

  seriesToSmooth.forEach((seriesName) => {
    LPF.smoothArray(smoothedNewSeries[seriesName]);
  });

  newDailyAvgs = newDailyAvgs.map((dp, day) => {
    const newDp = dp;
    seriesToSmooth.forEach((seriesName) => {
      newDp[seriesName + "_smooth"] = smoothedNewSeries[seriesName][day];
    });
    return newDp;
  });

  return {
    ...state,
    dailyAvgs: newDailyAvgs,
  };
};
