import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Sliders from "./Sliders";
import YearlyBreakdown from "./charts/YearlyBreakdown";
import DailyBreakdown from "./charts/DailyBreakdown";
import OutputPanel from "./charts/OutputPanel";
import Optimisation from "./charts/Optimisation";

import LaunchIcon from "@mui/icons-material/Launch";

const themeLight = createTheme({
  palette: {
    background: {
      default: "#EAEEF3",
    },
    secondary: {
      main: "#EA3D5F",
    },
  },
});

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={themeLight}>
        <CssBaseline />

        <Container maxWidth="md">
          <Typography variant="h3" style={{ marginTop: "30px" }}>
            Decarbonising UK Heating
          </Typography>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Modelling the impact on the electricity grid in 2050
          </Typography>
          {/* <br /> */}
          <Button
            variant="text"
            endIcon={<LaunchIcon />}
            href="https://ben-james.notion.site/UK-Heating-model-methodology-afe992fb43844543828400ef4bfaa877"
            target="blank"
          >
            Modelling breakdown
          </Button>
          <br />
          <br />
          <Sliders />
          <br />
          <YearlyBreakdown />
          <br />
          <DailyBreakdown />
          <br />
          <OutputPanel />
          <br />

          <p>
            Analysis of data from Gridwatch, Sheffield Solar, When2Heat, and
            MERRA.
          </p>
          <p>
            Built by <a href="https://benjames.io/">Ben James</a>. Methodology
            write-up:{" "}
            <a href="https://www.notion.so/ben-james/UK-Heating-model-methodology-afe992fb43844543828400ef4bfaa877">
              here
            </a>
            .
          </p>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default App;
