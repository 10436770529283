import { useDispatch, connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
// import LinearProgress from "@mui/material/LinearProgress";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import * as Action from "./store/action-types";
import * as sliderConfig from "./sliderConfig";

import "./sliders.css";

const mapStateToProps = (state) => {
  return { needsRecalculation: state.needsRecalculation };
};

const Sliders = ({ needsRecalculation }) => {
  const dispatch = useDispatch();

  useEffect(() => dispatch({ type: Action.RECALCULATE }), []);

  return (
    <Paper
      elevation={0}
      style={{
        padding: "0 35px 20px",
      }}
    >
      <Typography
        variant="h5"
        className="sectionTitle"
        style={{ paddingBottom: "15px" }}
      >
        MODEL INPUTS
      </Typography>
      <Grid container spacing={10}>
        <Grid item xs={12} md={6} className="sliderCol">
          <Typography variant="h6">Installed generation capacity</Typography>
          <Divider />

          <Typography gutterBottom mt={2}>
            Offshore wind (GW)
          </Typography>
          <Slider
            defaultValue={sliderConfig.offshoreInitialisedCapacity}
            valueLabelDisplay="auto"
            min={sliderConfig.offshoreMinSliderCapacity}
            max={sliderConfig.offshoreMaxCapacity}
            marks={sliderConfig.offshoreMarks}
            onChangeCommitted={(mouseEvent, val) => {
              dispatch({
                type: Action.REGISTER_SLIDER_CHANGE,
                slider: "offshore",
                value: val,
              });
            }}
          />

          <Typography gutterBottom>Onshore wind (GW)</Typography>
          <Slider
            defaultValue={sliderConfig.onshoreInitialisedCapacity}
            valueLabelDisplay="auto"
            min={sliderConfig.onshoreMinCapacity}
            max={sliderConfig.onshoreMaxCapacity}
            marks={sliderConfig.onshoreMarks}
            onChangeCommitted={(mouseEvent, val) => {
              dispatch({
                type: Action.REGISTER_SLIDER_CHANGE,
                slider: "onshore",
                value: val,
              });
            }}
          />

          <Typography gutterBottom>Solar (GW)</Typography>
          <Slider
            defaultValue={sliderConfig.solarInitialisedCapacity}
            valueLabelDisplay="auto"
            min={sliderConfig.solarMinCapacity}
            max={sliderConfig.solarMaxCapacity}
            marks={sliderConfig.solarMarks}
            onChangeCommitted={(mouseEvent, val) => {
              dispatch({
                type: Action.REGISTER_SLIDER_CHANGE,
                slider: "solar",
                value: val,
              });
            }}
          />

          <Typography gutterBottom>Nuclear (GW)</Typography>
          <Slider
            defaultValue={sliderConfig.nuclearInitialisedCapacity}
            valueLabelDisplay="auto"
            min={sliderConfig.nuclearMinCapacity}
            max={sliderConfig.nuclearMaxCapacity}
            marks={sliderConfig.nuclearMarks}
            onChangeCommitted={(mouseEvent, val) => {
              dispatch({
                type: Action.REGISTER_SLIDER_CHANGE,
                slider: "nuclear",
                value: val,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} className="sliderCol">
          <Typography variant="h6">Heating demand</Typography>
          <Divider />

          <Typography gutterBottom mt={2}>
            Domestic heat demand (TWh)
          </Typography>
          <Slider
            color="secondary"
            defaultValue={sliderConfig.heatInitialisedDemand}
            valueLabelDisplay="auto"
            min={sliderConfig.heatMinDemand}
            max={sliderConfig.heatMaxDemand}
            marks={sliderConfig.heatMarks}
            onChangeCommitted={(mouseEvent, val) => {
              dispatch({
                type: Action.REGISTER_SLIDER_CHANGE,
                slider: "heat",
                value: val,
              });
            }}
          />

          <Typography gutterBottom>Heat Pump / Hydrogen Mix</Typography>
          <Slider
            color="secondary"
            defaultValue={sliderConfig.hpMixInitial}
            valueLabelDisplay="auto"
            min={sliderConfig.hpMixMin}
            max={sliderConfig.hpMixMax}
            marks={sliderConfig.hpMixMarks}
            onChangeCommitted={(mouseEvent, val) => {
              dispatch({
                type: Action.REGISTER_SLIDER_CHANGE,
                slider: "hpMix",
                value: val,
              });
            }}
          />

          <Typography gutterBottom>Heat Pump efficiency (SPF)</Typography>
          <Slider
            color="secondary"
            defaultValue={sliderConfig.hpEffInitial}
            valueLabelDisplay="auto"
            min={sliderConfig.hpEffMin}
            max={sliderConfig.hpEffMax}
            marks={sliderConfig.hpEffMarks}
            onChangeCommitted={(mouseEvent, val) => {
              dispatch({
                type: Action.REGISTER_SLIDER_CHANGE,
                slider: "hpEff",
                value: val,
              });
            }}
          />

          {/* <Typography gutterBottom>Hydrogen system efficiency</Typography>
          <Slider
            defaultValue={sliderConfig.hpMixInitial}
            valueLabelDisplay="auto"
            min={sliderConfig.hpMixMin}
            max={sliderConfig.hpMixMax}
            marks={sliderConfig.hpMixMarks}
            onChangeCommitted={(e) => {
              dispatch({
                type: Action.SCALE_HEATING_TOT,
                factor: parseInt(e.target.innerText),
              });
            }}
          /> */}
        </Grid>
      </Grid>
      <div>
        <Button
          variant="contained"
          endIcon={<RefreshIcon />}
          disabled={!needsRecalculation}
          onClick={() => {
            dispatch({
              type: Action.RECALCULATE,
            });
          }}
        >
          Re-calculate
        </Button>
      </div>
      {/* {calculating && <LinearProgress color="secondary" />} */}
    </Paper>
  );
};

export default connect(mapStateToProps)(Sliders);
