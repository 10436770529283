import { shortfallDaysInitial } from "./shortfallDaysInitial";

export const calculateResults = (state) => {
  let newShortfallDays = calculateShortfallDays(state);

  return {
    ...state,
    results: {
      ...state.results,
      shortfallDays: newShortfallDays,
    },
  };
};

const calculateShortfallDays = (state, category) => {
  let newShortfallDays = shortfallDaysInitial;
  let categories = [
    "elec_for_all",
    "elec_for_all_wDailyS",
    "elec_for_all_wDailyS_wSeasonalS",
  ];
  categories.forEach((category) => {
    let categoryObj = newShortfallDays[category];
    let newCategoryObj = getShortFallDaysCategory(state, categoryObj, category);
    newShortfallDays[category] = newCategoryObj;
  });

  return newShortfallDays;
};

const getShortFallDaysCategory = (state, categoryObj, category) => {
  let percentiles = [50, 90, 95];
  // Reset counters
  percentiles.forEach((percentile) => {
    // for (const [bracket, val] of Object.entries(categoryObj[percentile])) {
    //   categoryObj[percentile][bracket] = 0;
    // }
    categoryObj[percentile]["dayList"] = [];
    categoryObj[percentile]["meanNumShortfallHrs"] = 0;
    categoryObj[percentile]["meanDefecit"] = 0;
  });

  let dailyDefecitSum = { 50: 0, 90: 0, 95: 0 };
  let shortfallHrsSum = { 50: 0, 90: 0, 95: 0 };

  for (let day = 0; day < 365; day++) {
    let dayResult = {
      50: { shortfallHrscount: 0, defecitCount: 0 },
      90: { shortfallHrscount: 0, defecitCount: 0 },
      95: { shortfallHrscount: 0, defecitCount: 0 },
    };

    for (let hour = 0; hour < 24; hour++) {
      let index = hour + day * 24;
      let hourObj = state.hourlySeries[index];
      percentiles.forEach((percentile) => {
        let defecit =
          hourObj[category + "_" + percentile] -
          hourObj["tot_generation_" + percentile];
        if (defecit > 0) {
          dayResult[percentile]["shortfallHrscount"]++;
          dayResult[percentile]["defecitCount"] += defecit;
        }
      });
    }

    percentiles.forEach((percentile) => {
      let meanDailyDefecit = dayResult[percentile]["defecitCount"] / 24;
      let dailyShortfallHrs = dayResult[percentile]["shortfallHrscount"];
      dailyDefecitSum[percentile] += meanDailyDefecit;
      shortfallHrsSum[percentile] += dailyShortfallHrs;

      // categoryObj[percentile]["dayList"].push({
      //   day: day,
      //   numShortfallHrs: dailyShortfallHrs,
      //   meanDailyDefecit: meanDailyDefecit,
      // });
    });
  }

  percentiles.forEach((percentile) => {
    categoryObj[percentile]["meanDefecit"] = dailyDefecitSum[percentile] / 365;
    categoryObj[percentile]["meanNumShortfallHrs"] =
      shortfallHrsSum[percentile] / 365;
  });

  return categoryObj;
};
