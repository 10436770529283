import { useDispatch, connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import * as Action from "../store/action-types";
// import * as sliderConfig from "./sliderConfig";

// import "./sliders.css";

const mapStateToProps = (state) => {
  return { optimisationResults: state.optimisationResults };
};

const Optimisation = ({ optimisationResults }) => {
  const dispatch = useDispatch();

  useEffect(() => dispatch({ type: Action.RECALCULATE }), []);

  return (
    <Paper
      elevation={0}
      style={{
        padding: "0 35px 20px",
      }}
    >
      <Typography
        variant="h5"
        className="sectionTitle"
        style={{ paddingBottom: "15px" }}
      >
        OPTIMISATION
      </Typography>

      <div>
        <Button
          variant="contained"
          endIcon={<RefreshIcon />}
          // disabled={!needsRecalculation}
          onClick={() => {
            dispatch({
              type: Action.RUN_OPTIMISATION,
            });
          }}
        >
          Run
        </Button>
      </div>

      <table>
        <tr>
          <th>Offshore</th>
          <th>Onshore</th>
          <th>Solar</th>
          <th>Nuclear</th>
          <th>HpMix</th>
          <th>Shortfall (wSeasonal)</th>
          <th>Defecit (wSeasonal)</th>
        </tr>
        {optimisationResults.map((r) => (
          <tr>
            <td>{r.iteration.offshore}</td>
            <td>{r.iteration.onshore}</td>
            <td>{r.iteration.solar}</td>
            <td>{r.iteration.nuclear}</td>
            <td>{r.iteration.hpMix}</td>
            <td>
              {r.results.shortfallDays.elec_for_all_wDailyS_wSeasonalS[
                "50"
              ].meanNumShortfallHrs.toFixed(1)}
            </td>
            <td>
              {r.results.shortfallDays.elec_for_all_wDailyS_wSeasonalS[
                "50"
              ].meanDefecit.toFixed(1)}
            </td>
          </tr>
        ))}
      </table>

      {/* <div>
        {optimisationResults.map((r) => (
          <div>
            <br></br>
            <p>{JSON.stringify(r.iteration)}</p>
            <div>
              Defecit:{" "}
              {r.results.shortfallDays.elec_for_all_wDailyS_wSeasonalS[
                "50"
              ].meanDefecit.toFixed(1)}
            </div>
            <div>
              Mean number shortfall Hrs:{" "}
              {r.results.shortfallDays.elec_for_all_wDailyS_wSeasonalS[
                "50"
              ].meanNumShortfallHrs.toFixed(1)}
            </div>
          </div>
        ))}
      </div> */}
    </Paper>
  );
};

export default connect(mapStateToProps)(Optimisation);
