import { Category } from "@mui/icons-material";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Label,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { MONTH_NAMES_SHORT } from "./Utils";

const ShortfallBar = ({ shortfallDays, category }) => {
  const data = [
    shortfallDays["elec_for_all"],
    shortfallDays["elec_for_all_wDailyS"],
    shortfallDays["elec_for_all_wDailyS_wSeasonalS"],
  ];
  return (
    <div
      style={{
        paddingBottom: "35%",
        position: "relative",
        /* height: 0; */
        // width: "85%",
        // margin: "5%",
      }}
    >
      <div className="innerChartDiv" style={{ height: "300px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <XAxis
              dataKey="name"
              tickLine={false}
              height={40}
              // ticks={MONTH_NAMES_SHORT}
            />
            {/* <YAxis>
              <Label
                value="GWh H2 stored"
                // offset={20}
                // position="insideBottomLeft"
              />
            </YAxis> */}
            <YAxis
              yAxisId="left"
              orientation="left"
              stroke="#EA3D5F"
              value="Mean deficit (MW)"
              // offset={30}
              width={80}
            >
              <Label
                value="Mean deficit (MW)"
                angle={-90}
                offset={10}
                fill="#EA3D5F"
                position="insideBottomLeft"
              />
            </YAxis>
            <YAxis
              yAxisId="right"
              orientation="right"
              stroke="orange"
              width={80}
            >
              <Label
                value="Mean number of shortfall hours"
                angle={90}
                offset={20}
                fill="orange"
                position="insideBottomRight"
              />
            </YAxis>

            <Tooltip formatter={(val) => +val.toFixed(1)} />

            <Bar
              name="Mean deficit"
              dataKey="50.meanDefecit"
              fill="#EA3D5F"
              yAxisId="left"
              // isAnimationActive={true}
              // unit=" GWh"
            />
            <Bar
              name="Mean number of daily shortfall Hrs "
              dataKey="50.meanNumShortfallHrs"
              fill="orange"
              yAxisId="right"
              // isAnimationActive={false}
              // unit=" GWh"
            />
          </BarChart>
          {/* <BarChart data={shortfallDays[category][50]["dayList"]}>
          <XAxis
            dataKey="day"
            tickLine={false}
            height={40}
            // ticks={MONTH_NAMES_SHORT}
          />
          <YAxis width={80}>
            <Label
              value="GWh H2 stored"
              angle={-90}
              offset={20}
              position="insideBottomLeft"
            />
          </YAxis>

          <Tooltip formatter={(val) => Math.floor(val)} />
          <Bar
            name="H2 seasonal storage used"
            dataKey="numShortfallHrs"
            fill="orange"
            isAnimationActive={false}
            unit=" GWh"
          />
          <Bar
            name="H2 seasonal storage used"
            dataKey="meanDailyDefecit"
            fill="pink"
            isAnimationActive={false}
            unit=" GWh"
          />
        </BarChart> */}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ShortfallBar;
