export const yearSliderMarks = [
  {
    value: 15,
    label: "Jan",
  },
  {
    value: 46,
    label: "Feb",
  },
  {
    value: 74,
    label: "Mar",
  },
  {
    value: 104,
    label: "Apr",
  },
  {
    value: 134,
    label: "May",
  },
  {
    value: 165,
    label: "Jun",
  },
  {
    value: 195,
    label: "Jul",
  },
  {
    value: 226,
    label: "Aug",
  },
  {
    value: 257,
    label: "Sept",
  },
  {
    value: 287,
    label: "Oct",
  },
  {
    value: 318,
    label: "Nov",
  },
  {
    value: 348,
    label: "Dec",
  },
];
