import { connect } from "react-redux";
import { useState } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import {
  Line,
  Area,
  ComposedChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
} from "recharts";

import { MONTH_NAMES_SHORT } from "./Utils";
import "./charts.css";
import HydrogenYearlyBar from "./HydrogenYearlyBar";

const mapStateToProps = (state) => {
  return { dailyAvgs: state.dailyAvgs };
};

const YearlyBreakdown = ({ dailyAvgs }) => {
  const [displayType, setDisplayType] = useState("summary");
  const [h2Storage, setH2Storage] = useState(false);

  const handleDisplayType = (event, newDisplayType) => {
    setDisplayType(newDisplayType);
  };

  return (
    <Container
      style={{
        padding: "0px",
      }}
    >
      <Paper elevation={0}>
        <Typography variant="h5" className="sectionTitle">
          YEARLY OVERVIEW
        </Typography>
        <Typography variant="subtitle1">
          Average daily electricity supply and demand in 2050.
        </Typography>
        <ToggleButtonGroup
          value={displayType}
          exclusive
          onChange={handleDisplayType}
          className="ToggleButtonGroup"
        >
          <ToggleButton value="summary" aria-label="left aligned">
            Summary
          </ToggleButton>
          <ToggleButton value="generation" aria-label="centered">
            Generation
          </ToggleButton>
          <ToggleButton value="demand" aria-label="centered">
            Demand
          </ToggleButton>
        </ToggleButtonGroup>
        <div className="outerChartDiv">
          <div className="innerChartDiv">
            <ResponsiveContainer>
              <ComposedChart data={dailyAvgs}>
                <Legend verticalAlign="top" iconType="plainline" />

                <XAxis
                  dataKey="month"
                  tickLine={false}
                  height={40}
                  ticks={MONTH_NAMES_SHORT}
                >
                  <Label value="Time of year" offset={-10} position="bottom" />
                </XAxis>
                <YAxis width={80}>
                  <Label
                    value="Average Power (MW)"
                    angle={-90}
                    position="insideLeft"
                  />
                </YAxis>

                {displayType === "summary" && (
                  <Line
                    name="Total electricity generation"
                    dataKey="tot_generation_50"
                    stroke="#00afb9"
                    dot={false}
                    strokeWidth={3}
                  />
                )}
                {displayType === "summary" && (
                  <Line
                    name={
                      h2Storage
                        ? "Total electricity demand (without H2 storage)"
                        : "Total electricity demand"
                    }
                    dataKey="elec_for_all_50"
                    stroke={h2Storage ? "#f7dfe4" : "#ef476f"}
                    dot={false}
                    strokeWidth={3}
                  />
                )}
                {displayType === "summary" && h2Storage && (
                  <Line
                    name="Total electricity demand (with seasonal H2 storage)"
                    dataKey="elec_for_all_wSeasonalS_50_smooth"
                    stroke="#ef476f"
                    dot={false}
                    strokeDasharray="7 7"
                    strokeWidth={3}
                  />
                )}

                {displayType === "generation" && (
                  <Area
                    name="nuclear"
                    type="monotone"
                    stackId="1"
                    dataKey="nuclear_50_scaled"
                    stroke="grey"
                    fill="#6d6875"
                  />
                )}
                {displayType === "generation" && (
                  <Area
                    name="solar"
                    type="monotone"
                    stackId="1"
                    dataKey="solar_50_scaled"
                    stroke="#ffb703"
                    fill="#ffb703"
                  />
                )}
                {displayType === "generation" && (
                  <Area
                    name="offshore"
                    type="monotone"
                    stackId="1"
                    dataKey="offshore_50_scaled"
                    stroke="#7AC5D7"
                    fill="#219ebc"
                  />
                )}
                {displayType === "generation" && (
                  <Area
                    name="onshore"
                    type="monotone"
                    stackId="1"
                    dataKey="onshore_50_scaled"
                    stroke="#BBDFF0"
                    fill="#8ecae6"
                  />
                )}

                {displayType === "demand" && (
                  <Area
                    name="Non-heat electricity demand"
                    type="monotone"
                    stackId="2"
                    dataKey="elec_avg_tot"
                    stroke="#B3B3B3"
                    fill="grey"
                  />
                )}
                {displayType === "demand" && (
                  <Area
                    name="Electricity for heat pumps"
                    type="monotone"
                    stackId="2"
                    dataKey="elec_for_hp_50"
                    stroke="#7F84BD"
                    fill="#293291"
                  />
                )}
                {displayType === "demand" && (
                  <Area
                    name="Electricity for hydrogen"
                    type="monotone"
                    stackId="2"
                    dataKey={
                      h2Storage
                        ? "elec_for_h2_wDailyS_wSeasonalS_50_smooth"
                        : "elec_for_h2_50"
                    }
                    stroke="#ee6c4d"
                    fill="#ee6c4d"
                  />
                )}

                <Tooltip formatter={(val) => Math.floor(val)} />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
        {/* <Typography variant="subtitle2">
          *Assumes H2 production on the day of consumption.
        </Typography> */}
        <br />
        <ToggleButton
          color="primary"
          selected={h2Storage}
          onChange={() => {
            setH2Storage(!h2Storage);
          }}
          className="h2Toggle"
        >
          Toggle Seasonal Hydrogen Storage
        </ToggleButton>
        <br />
        {h2Storage &&
          (dailyAvgs[54]["seasonal_h2_used_50"] === 0 ? (
            <Typography variant="subtitle2" style={{ paddingBottom: "20px" }}>
              Seasonal hydrogen storage not relevant (no crossover of supply &
              demand)
            </Typography>
          ) : (
            <HydrogenYearlyBar dailyAvgs={dailyAvgs} />
          ))}
      </Paper>
    </Container>
  );
};

export default connect(mapStateToProps)(YearlyBreakdown);
