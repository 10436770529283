import { monthFromDay } from "./reducerUtils";
import {
  calculateDailyStorage,
  calculateSeasonalStorage,
  smoothStorageSeriesForDisplay,
} from "./storage";
import { calculateResults } from "./results";

import {
  H2_EFFICIENCY_BEFORE_BOILER,
  H2_BOILER_EFFICIENCY,
} from "./modelConstants";

let LPF = require("lpf");

let HEAT_SERIES = ["heat", "hpMix", "hpEff"];
let SLIDERS_FOR_SCALING = ["heat", "offshore", "onshore", "solar", "nuclear"];

export const recalculate = (state) => {
  // Iterate over all series and
  // (1) Scale series where needed
  // (2) Calculate new elec_hp & elec_h2 (where needed)

  console.time("seriesScaling");
  let newHourlySeries = state.hourlySeries.map((hourObj) => {
    var updatedHour = hourObj;
    // For every slider that was changed...
    for (let i = 0; i < state.changedSliders.length; i++) {
      let slider = state.changedSliders[i];
      // If we need to scale the series
      if (SLIDERS_FOR_SCALING.includes(slider)) {
        updatedHour = scaleChangedSliderVal(
          hourObj,
          slider,
          state.modelInputs[slider]
        );
        // Calculate generation totals
        updatedHour = updateGenerationTotals(updatedHour);
      }

      // If we need to recalc electricity demand from heat
      if (HEAT_SERIES.includes(slider)) {
        // console.log("Yup, changing stuff");
        updatedHour = calculateElecDemandFromHeat(hourObj, state);
      }
    }

    updatedHour = updateSurplus(updatedHour);

    return updatedHour;
  });
  console.timeEnd("seriesScaling");

  let newState = {
    ...state,
    hourlySeries: newHourlySeries,
  };

  console.time("calculateNewDailyAvgs");
  newState = calculateNewDailyAvgs(newState);
  console.timeEnd("calculateNewDailyAvgs");

  console.time("calculateSeasonalStorage");
  newState = calculateSeasonalStorage(newState);
  console.timeEnd("calculateSeasonalStorage");

  console.time("calculateDailyStorage");
  newState = calculateDailyStorage(newState);
  console.timeEnd("calculateDailyStorage");

  // for (let day = 0; day < 365; day++) {
  //   let initialDemandSum = 0;
  //   let dailyDemandSum = 0;
  //   let dailySeasonalDemandSum = 0;
  //   let generationSum = 0;
  //   for (let hour = 0; hour < 24; hour++) {
  //     let index = hour + day * 24;
  //     let hourObj = newState.hourlySeries[index];

  //     initialDemandSum += hourObj["elec_for_all_50"];
  //     dailyDemandSum += hourObj["elec_for_all_wDailyS_50"];
  //     dailySeasonalDemandSum += hourObj["elec_for_all_wDailyS_wSeasonalS_50"];
  //     generationSum += hourObj["tot_generation_50"];
  //   }
  //   console.log(
  //     `day: ${day}  initialDemand: ${
  //       initialDemandSum / 24
  //     }    demand wDailyS: ${dailyDemandSum / 24}    demand wSeasonals: ${
  //       dailySeasonalDemandSum / 24
  //     }   gen: ${generationSum / 24}`
  //   );
  // }

  // console.time("smoothStorageSeriesForDisplay");
  // newState = smoothStorageSeriesForDisplay(newState);
  // console.timeEnd("smoothStorageSeriesForDisplay");

  console.time("calculateResults");
  newState = calculateResults(newState);
  console.timeEnd("calculateResults");

  console.time("updateFocusDay");
  newState = updateFocusDay(
    newState,
    newState.focusDay,
    newState.focusPercentile
  );
  console.timeEnd("updateFocusDay");

  return {
    ...newState,
    changedSliders: [],
    needsRecalculation: false,
  };
};

const scaleChangedSliderVal = (hourObj, slider, newSliderVal) => {
  let updatedHour = hourObj;

  ["50", "90", "95"].forEach((percentile) => {
    let seriesName = slider + "_" + percentile;

    if (slider !== "nuclear") {
      updatedHour[seriesName + "_scaled"] = hourObj[seriesName] * newSliderVal;
    } else {
      updatedHour[seriesName + "_scaled"] = 1000 * newSliderVal;
    }
  });

  return updatedHour;
};

const calculateElecDemandFromHeat = (hourObj, state) => {
  let updatedHour = hourObj;
  let hpMix = (100 - state.modelInputs.hpMix) / 100;
  let hpSPF = state.modelInputs.hpEff / 100;

  ["50", "90", "95"].forEach((percentile) => {
    let heatDemand = updatedHour["heat_" + percentile + "_scaled"];
    // Electricity for HPs
    updatedHour["elec_for_hp_" + percentile] = (heatDemand * hpMix) / hpSPF;
    // Electricity for H2
    updatedHour["h2_demand_" + percentile] =
      (heatDemand * (1 - hpMix)) / H2_BOILER_EFFICIENCY;
    updatedHour["elec_for_h2_" + percentile] =
      updatedHour["h2_demand_" + percentile] / H2_EFFICIENCY_BEFORE_BOILER;

    let elecForAll =
      updatedHour["elec_avg_tot"] +
      updatedHour["elec_for_h2_" + percentile] +
      updatedHour["elec_for_hp_" + percentile];
    // Total (heat & non-heat)
    updatedHour["elec_for_all_" + percentile] = elecForAll;
  });
  return updatedHour;
};

const getSeriesToRecalcAvgOf = (state) => {
  // Find out if any of the heat sliders have been changed
  let heatChanged = HEAT_SERIES.some(
    (r) => state.changedSliders.indexOf(r) >= 0
  );
  // If electricity changed
  let elecChanged = ["onshore", "offshore", "nuclear", "solar"].some(
    (r) => state.changedSliders.indexOf(r) >= 0
  );

  let seriesToRecalcAvgsOf = state.changedSliders;
  // Remove any sliders that don't need to be scaled
  seriesToRecalcAvgsOf = seriesToRecalcAvgsOf.filter((slider) =>
    SLIDERS_FOR_SCALING.includes(slider)
  );

  // We only need averages (50%) for the yearly graph
  seriesToRecalcAvgsOf = seriesToRecalcAvgsOf.map((s) => s + "_50_scaled");
  seriesToRecalcAvgsOf.push("elec_surplus_final_50"); // Always needs to be recalced

  if (heatChanged) {
    seriesToRecalcAvgsOf.push(
      "heat_50_scaled",
      "elec_for_hp_50",
      "elec_for_h2_50",
      // "elec_for_h2_wDailyS_50",
      "elec_for_all_50"
    );
  }

  if (elecChanged) {
    seriesToRecalcAvgsOf.push("tot_generation_50");
  }

  if (!("elec_avg_tot" in state.dailyAvgs[0])) {
    seriesToRecalcAvgsOf.push("elec_avg_tot");
  }

  return seriesToRecalcAvgsOf;
};

const calculateNewDailyAvgs = (state) => {
  let seriesToRecalcAvgsOf = getSeriesToRecalcAvgOf(state);

  LPF.smoothing = 0.05;

  let averagedNewSeries = {};
  seriesToRecalcAvgsOf.forEach((seriesName) => {
    averagedNewSeries[seriesName] = getAveragedSeries(
      state.hourlySeries,
      seriesName
    );

    // If want to preserve an unsmoothed series
    // if (["tot_generation_50", "elec_for_all_50"].includes(seriesName)) {
    if (["elec_surplus_final_50"].includes(seriesName)) {
      let smoothName = seriesName + "_smooth";
      // Deep clone
      averagedNewSeries[smoothName] = JSON.parse(
        JSON.stringify(averagedNewSeries[seriesName])
      );
      // Doens't actually recalc average, just makes sure it gets assigned below.
      LPF.smoothArray(averagedNewSeries[smoothName]);
      seriesToRecalcAvgsOf.push(smoothName);

      // LPF.smoothArray(averagedNewSeries[seriesName]);
      // } else if (["elec_surplus_final_50"].includes(seriesName)) {
      // if (["elec_surplus_final_50"].includes(seriesName)) {
      // Do not fucking smooth it
    } else {
      LPF.smoothArray(averagedNewSeries[seriesName]);
    }
  });

  let newDailyAvgs = state.dailyAvgs.map((dp, day) => {
    const newDp = dp;
    newDp["month"] = monthFromDay(day);
    seriesToRecalcAvgsOf.forEach((seriesName) => {
      newDp[seriesName] = averagedNewSeries[seriesName][day];
    });
    return newDp;
  });
  return {
    ...state,
    dailyAvgs: newDailyAvgs,
  };
};

const getAveragedSeries = (newHourlySeries, seriesName, smooth = false) => {
  let runningAvg = 0;
  let avgSeries = [];

  newHourlySeries.forEach((dp, hour) => {
    runningAvg += dp[seriesName];

    // Every 24 vals, take the average and reset
    if ((hour + 1) % 24 === 0 && hour !== 0) {
      avgSeries.push(runningAvg / 24);
      runningAvg = 0;
    }
  });

  return avgSeries;
};

const updateGenerationTotals = (updatedHour) => {
  for (const percentile of ["50", "90", "95"]) {
    updatedHour[`tot_generation_${percentile}`] =
      updatedHour[`offshore_${percentile}_scaled`] +
      updatedHour[`onshore_${percentile}_scaled`] +
      updatedHour[`solar_${percentile}_scaled`] +
      updatedHour[`nuclear_${percentile}_scaled`];
  }
  return updatedHour;
};

const updateSurplus = (updatedHour) => {
  for (const percentile of ["50", "90", "95"]) {
    updatedHour["elec_surplus_final_" + percentile] =
      updatedHour["tot_generation_" + percentile] -
      updatedHour["elec_for_all_" + percentile];
  }
  return updatedHour;
};

export const updateFocusDay = (state, day, percentile) => {
  const focusDaySeries = [
    "heat",
    "solar",
    "offshore",
    "onshore",
    "nuclear",
    "elec_avg_tot",
    "elec_for_all",
    "elec_for_hp",
    "elec_for_h2",
    "tot_generation",
    "elec_for_all_wDailyS",
    "elec_for_all_wDailyS_wSeasonalS",
    "elec_for_h2_wDailyS",
    "elec_for_h2_wDailyS_wSeasonalS",
  ];

  // console.log(`day: ${day}`);
  // console.log(`Using percentile: ${percentile}`);

  // First transform into an oject of arrays, so can low pass filter
  let newDailyList = {};
  focusDaySeries.forEach((seriesName) => {
    let percentileSeriesName;

    if (seriesName === "elec_avg_tot") {
      percentileSeriesName = seriesName;
    } else if (
      ["heat", "solar", "offshore", "onshore", "nuclear"].includes(seriesName)
    ) {
      percentileSeriesName =
        seriesName + "_" + percentile.toString() + "_scaled";
    } else {
      percentileSeriesName = seriesName + "_" + percentile.toString();
    }

    newDailyList[seriesName] = [];
    state.hourlyChartSeries.forEach((dp, i) => {
      let index = 24 * (day - 1) + i;
      newDailyList[seriesName].push(
        state.hourlySeries[index][percentileSeriesName]
      );
    });

    if (seriesName === "onshore" || seriesName === "offshore") {
      LPF.smoothing = 0.5;
      newDailyList[seriesName] = LPF.smoothArray(newDailyList[seriesName]);
    }
  });

  // Now transform back into Array of objects and re-assign new values
  var newHourlyChartSeries = state.hourlyChartSeries.map((dp, i) => {
    const newDp = dp;
    focusDaySeries.forEach((seriesName) => {
      newDp[seriesName] = newDailyList[seriesName][i];
    });
    return newDp;
  });

  return {
    ...state,
    hourlyChartSeries: newHourlyChartSeries,
    focusDay: day,
    focusPercentile: percentile,
  };
};
