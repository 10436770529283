import { recalculate } from "./calculations";

const searchRange = [
  // {
  //   offshore: 90,
  //   onshore: 30,
  //   solar: 90,
  //   nuclear: 30,
  //   hpMix: 0,
  // },
  // {
  //   offshore: 90,
  //   onshore: 30,
  //   solar: 90,
  //   nuclear: 30,
  //   hpMix: 80,
  // },
];

// let offshoreMin = 60;
// let offshoreMax = 120;
// let solarMin = 60;
// let solarMax = 120;
// let nuclearMin = 60;
// let nuclearMax = 120;
// let hpMixMin = 60;
// let hpMixMax = 120;

let offshoreSeries = [60, 80, 100, 120];
let onshoreSeries = [20, 35, 50, 65];
let solarSeries = [60, 80, 100, 120];
let nuclearSeries = [10, 20, 30, 40];

let hpMixSeries = [];
for (let i = 0; i < 20; i++) {
  hpMixSeries.push(i);
}
// let hpMixSeries = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

let offshore = 113;
let onshore = 45;
let solar = 78;
let nuclear = 17;
let hpMix = 15;

let FAC = 1;

// for (let iWind = 0; iWind < offshoreSeries.length; iWind++) {
//   offshore = offshoreSeries[iWind];
//   onshore = onshoreSeries[iWind];
// for (let iSolar = 0; iSolar < solarSeries.length; iSolar++) {
// let solar = solarSeries[iSolar];
// for (let iNuclear = 0; iNuclear < nuclearSeries.length; iNuclear++) {
// let nuclear = nuclearSeries[iNuclear];
// for (let iHpMix = 0; iHpMix < hpMixSeries.length; iHpMix++) {
//   hpMix = hpMixSeries[iHpMix];

for (let i = -10; i <= 10; i++) {
  offshore = 113 + i * FAC * (1 / 0.39);
  solar = 78 - i * FAC * (1 / 0.107);

  searchRange.push({
    offshore,
    onshore,
    solar,
    nuclear,
    hpMix,
  });
}
// }
// }
//   }
// }

export const runOptimisation = (state) => {
  let newOptimisationResults = [];
  let fakeState = structuredClone(state);

  searchRange.forEach((iteration) => {
    fakeState.changedSliders = [];
    console.log(`Running ${iteration}`);
    for (const [slider, sliderValue] of Object.entries(iteration)) {
      fakeState.changedSliders.push(slider);
      fakeState.modelInputs[slider] = sliderValue;
    }

    let calculatedState = recalculate(fakeState);
    newOptimisationResults.push({
      iteration: iteration,
      results: structuredClone(calculatedState.results),
      // test: calculatedState.results.shortfallDays
      //   .elec_for_all_wDailyS_wSeasonalS["50"].meanDefecit,
    });
  });

  return {
    ...state,
    optimisationResults: newOptimisationResults,
  };
};
