import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Label,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { MONTH_NAMES_SHORT } from "./Utils";

const HydrogenYearlyBar = ({ dailyAvgs }) => (
  <div
    style={{
      paddingBottom: "25%",
      position: "relative",
      /* height: 0; */
      // width: "85%",
      // margin: "5%",
    }}
  >
    <div className="innerChartDiv" style={{ height: "225px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={dailyAvgs}>
          <XAxis
            dataKey="month"
            tickLine={false}
            height={40}
            ticks={MONTH_NAMES_SHORT}
          />
          <YAxis width={80}>
            <Label
              value="GWh H2 storage used"
              angle={-90}
              offset={20}
              position="insideBottomLeft"
            />
          </YAxis>

          <Tooltip formatter={(val) => Math.floor(val)} />
          <Bar
            name="H2 seasonal storage used"
            dataKey="seasonal_h2_used_mwh_h2_50_smooth"
            fill="orange"
            isAnimationActive={false}
            unit=" GWh"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
);

export default HydrogenYearlyBar;
