export const offshoreMinSliderCapacity = 0;
export const offshoreNowCapacity = 11;
export const offshoreMinCapacity = 60;
export const offshoreInitialisedCapacity = 113;
export const offshoreMaxCapacity = 130;

export const offshoreMarks = [
  {
    value: offshoreMinSliderCapacity,
    label: offshoreMinSliderCapacity,
  },
  {
    value: offshoreNowCapacity,
    label: "Now",
  },
  // {
  //   value: offshoreMinCapacity,
  //   label: offshoreMinCapacity,
  // },
  {
    value: offshoreMaxCapacity,
    label: offshoreMaxCapacity,
  },
];

export const onshoreMinCapacity = 0;
export const onshoreNowCapacity = 14;
export const onshoreInitialisedCapacity = 45;
export const onshoreMaxCapacity = 60;

export const onshoreMarks = [
  {
    value: onshoreMinCapacity,
    label: onshoreMinCapacity,
  },
  {
    value: onshoreNowCapacity,
    label: "Now",
  },
  {
    value: onshoreMaxCapacity,
    label: onshoreMaxCapacity,
  },
];

export const solarMinCapacity = 0;
export const solarNowCapacity = 14;
export const solarInitialisedCapacity = 78;
export const solarMaxCapacity = 120;

export const solarMarks = [
  {
    value: solarMinCapacity,
    label: solarMinCapacity,
  },
  {
    value: solarNowCapacity,
    label: "Now",
  },
  {
    value: solarMaxCapacity,
    label: solarMaxCapacity,
  },
];

export const nuclearMinCapacity = 0;
export const nuclearNowCapacity = 7;
export const nuclearInitialisedCapacity = 20;
export const nuclearMaxCapacity = 40;

export const nuclearMarks = [
  {
    value: nuclearMinCapacity,
    label: nuclearMinCapacity,
  },
  {
    value: nuclearNowCapacity,
    label: "Now",
  },
  {
    value: nuclearMaxCapacity,
    label: nuclearMaxCapacity,
  },
];

export const heatMinDemand = 200;
export const heatNowDemand = 390;
export const heatInitialisedDemand = 250;
export const heatMaxDemand = 410;

export const heatMarks = [
  {
    value: heatMinDemand,
    label: heatMinDemand,
  },
  {
    value: heatNowDemand,
    label: "Now",
  },
  {
    value: heatMaxDemand,
    label: heatMaxDemand,
  },
];

export const hpMixMin = 0;
export const hpMixInitial = 30;
export const hpMixMax = 100;

export const hpMixMarks = [
  {
    value: hpMixMin,
    label: "100% H.P.",
  },
  {
    value: hpMixMax,
    label: "100% H2",
  },
];

export const hpEffMin = 260;
export const hpEffInitial = 290;
export const hpEffMax = 320;

export const hpEffMarks = [
  {
    value: hpEffMin,
    label: "260%",
  },
  {
    value: hpEffMax,
    label: "320%",
  },
];
