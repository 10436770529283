export const scaleSeries = (state, seriesName, factor) => {
  console.log(`Starting scaling ${seriesName}`);
  let newhourlySeries = state.hourlySeries.map((dp) => {
    const scaledDp = dp;
    // console.log(action.factor); // This log REALLY slows things down!
    scaledDp[seriesName + "_scaled"] = dp[seriesName] * factor;
    return scaledDp;
  });
  console.log("done");

  return {
    ...state,
    hourlySeries: newhourlySeries,
  };
};

export const monthFromDay = (day) => {
  // This method returns December when day = 0
  if (day === 0) {
    day = 1;
  }
  let date = new Date(2009, 0, day);
  return date.toLocaleString("default", { month: "short" });
};
