export const shortfallDaysInitial = {
  elec_for_all: {
    50: {
      dayList: [],
      meanNumShortfallHrs: 0,
      meanDefecit: 0,
    },
    90: {
      dayList: [],
      meanNumShortfallHrs: 0,
      meanDefecit: 0,
    },
    95: {
      dayList: [],
      meanNumShortfallHrs: 0,
      meanDefecit: 0,
    },
    name: "Without storage",
  },
  elec_for_all_wDailyS: {
    50: {
      dayList: [],
      meanNumShortfallHrs: 0,
      meanDefecit: 0,
    },
    90: {
      dayList: [],
      meanNumShortfallHrs: 0,
      meanDefecit: 0,
    },
    95: {
      dayList: [],
      meanNumShortfallHrs: 0,
      meanDefecit: 0,
    },
    name: "With daily H2 storage",
  },
  elec_for_all_wDailyS_wSeasonalS: {
    50: {
      dayList: [],
      meanNumShortfallHrs: 0,
      meanDefecit: 0,
    },
    90: {
      dayList: [],
      meanNumShortfallHrs: 0,
      meanDefecit: 0,
    },
    95: {
      dayList: [],
      meanNumShortfallHrs: 0,
      meanDefecit: 0,
    },
    name: "With daily and seasonal H2 storage",
  },
};
