import { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import "./charts.css";
import { yearSliderMarks } from "./yearSliderMarks";

import {
  Line,
  ComposedChart,
  Legend,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

import * as Action from "../store/action-types";
import HourlyChart from "./HourlyChart";

const mapStateToProps = (state) => {
  return { hourlyChartSeries: state.hourlyChartSeries };
};

const DailyBreakdown = ({ hourlyChartSeries }) => {
  const dispatch = useDispatch();

  const [displayType, setDisplayType] = useState("summary");
  const [percentile, setPercentile] = useState(50);
  const [h2DailyStorage, setH2DailyStorage] = useState(false);
  const [h2SeasonalStorage, setH2SeasonalStorage] = useState(false);
  const [focusDay, setFocusDay] = useState(183);

  const handleDisplayType = (e, newDisplayType) => {
    // Don't allow no button to be clicked
    if (newDisplayType !== null) {
      setDisplayType(newDisplayType);
    }
  };

  const handlePercentile = (e, newPercentile) => {
    if (newPercentile !== null) {
      setPercentile(newPercentile);
    }
  };

  useEffect(() => {
    dispatch({
      type: Action.UPDATE_FOCUS_DAY,
      day: focusDay,
      percentile: percentile,
    });
  }, [percentile, focusDay, h2DailyStorage, h2SeasonalStorage]);

  return (
    <Container
      style={{
        padding: "0px",
      }}
    >
      <Paper elevation={0}>
        <Typography variant="h5" className="sectionTitle">
          DAILY BREAKDOWN
        </Typography>

        <div className="daySelector">
          <Slider
            defaultValue={183}
            valueLabelDisplay="auto"
            min={1}
            max={365}
            marks={yearSliderMarks}
            onChangeCommitted={(mouseEvent, newFocusDay) => {
              setFocusDay(newFocusDay);
            }}
          />
          <XAxis></XAxis>
        </div>

        <ToggleButtonGroup
          value={displayType}
          exclusive
          color="primary"
          onChange={handleDisplayType}
          className="ToggleButtonGroup"
        >
          <ToggleButton value="summary" aria-label="left aligned">
            Summary
          </ToggleButton>
          <ToggleButton value="supply" aria-label="left aligned">
            Supply
          </ToggleButton>
          <ToggleButton value="demand" aria-label="centered">
            Demand
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          value={percentile}
          exclusive
          color="primary"
          onChange={handlePercentile}
          className="ToggleButtonGroup"
        >
          <ToggleButton value={50} aria-label="left aligned">
            Avg
          </ToggleButton>
          <ToggleButton value={90} aria-label="centered">
            Worst 10%
          </ToggleButton>
          <ToggleButton value={95} aria-label="centered">
            Worst 5%
          </ToggleButton>
        </ToggleButtonGroup>

        <HourlyChart
          hourlyChartSeries={hourlyChartSeries}
          displayType={displayType}
          h2DailyStorage={h2DailyStorage}
          h2SeasonalStorage={h2SeasonalStorage}
        />

        <br />
        <ToggleButton
          color="primary"
          selected={h2DailyStorage}
          onChange={() => {
            setH2DailyStorage(!h2DailyStorage);
          }}
          className="h2Toggle"
        >
          Toggle Daily Hydrogen Storage
        </ToggleButton>

        <ToggleButton
          color="primary"
          disabled={!h2DailyStorage}
          selected={h2SeasonalStorage}
          onChange={() => {
            setH2SeasonalStorage(!h2SeasonalStorage);
          }}
          className="h2Toggle"
        >
          Toggle Seasonal Hydrogen Storage
        </ToggleButton>
      </Paper>
    </Container>
  );
};

export default connect(mapStateToProps)(DailyBreakdown);
